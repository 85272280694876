import React, { useMemo, useState } from 'react';
import classnames from 'classnames';

import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import Carousel from 'components/uiLibrary/Carousel';
import ReviewModal from 'components/Reviews/Display/ReviewModal';

import queries from 'containers/Reviews/queries';

import { useQuery } from 'utils/react-query';
import {
  ACCESS_TYPE,
  ENTITY_DISPLAY_STATUS,
  ENTITY_TYPE,
  POPUP_SOURCES,
  PREMIUM_NUDGE_POSITION,
  PREMIUM_NUDGE_VIEW_TYPES,
  TP,
  VALID_QUERY_PARAMS,
} from 'constants/index';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { createHorizontalSections } from 'utils/globals/app';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import { artistEditTabValues } from 'containers/Artist/constants';
import { REVIEW_SECTIONS } from 'constants/consts';

import MultilineEllipses from 'components/uiLibrary/MultilineEllipses';
import EmptySectionBlock from '../EmptySectionBlock';
import SectionBlock from '../SectionBlock';

import classes from './EntityFeaturedReview.module.scss';
import { COMPONENTS, SECTIONS } from '../Analytics/constants';
import useTracking from '../Analytics';
import { useTrackingClickStyles } from '../TrackingTester';

const EntityFeaturedReview = ({ entityType, entity, isEditMode }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const track = useTracking();
  const [modalReview, setModalReview] = useState(null);
  const { navigate } = usePageContext();
  const hasPremiumSubscription = entity?.stats?.isPro;
  const { isMobile, isDesktop } = useDeviceTypeLayouts();
  const trackingData = {
    section: SECTIONS.REVIEW,
    component: COMPONENTS.FEATURED_REVIEW,
  };
  const trackingClasses = useTrackingClickStyles(trackingData, false);
  const { data, isLoading } = useQuery(
    queries.getFeaturedReviews({
      entityType,
      entityId: entity?.id,
      limit: 10,
      ...(isEditMode && {
        asEdit: true,
      }),
      queryConfig: {
        enabled: !!entity?.id,
        select: response => response?.data?.filter(review => review?.highlight),
      },
    }),
  );

  const featuredReviews = useMemo(() => {
    if (!data?.length) {
      return [];
    }

    const entityKey = entityType === ENTITY_TYPE?.ARTIST ? ACCESS_TYPE.PROFILE : ACCESS_TYPE.ORGANIZATION;

    return data?.filter(review =>
      review?.tags?.find(tag => {
        if (tag?.[entityKey]?.id === entity?.id) {
          if (hasPremiumSubscription) {
            return tag?.displayStatus === ENTITY_DISPLAY_STATUS.SHOW;
          }
          return true;
        }
        return false;
      }),
    );
  }, [data, hasPremiumSubscription, entity, entityType]);

  const linkProps = useMemo(
    () =>
      isEditMode
        ? navigate.getLinkProps({
            entity,
            entityType,
            edit: true,
            queryParams: {
              tab: artistEditTabValues.reviews,
              [VALID_QUERY_PARAMS.OPEN_MODAL]: REVIEW_SECTIONS.GENERAL_REVIEWS,
            },
          })
        : null,
    [isEditMode, entity, entityType, navigate],
  );

  const onOpenReviewModal = (e, review) => {
    e.stopPropagation();
    e.preventDefault();

    setModalReview(review);
    track.click({
      ...trackingData,
      component: COMPONENTS.REVIEW_MODAL,
    });
  };

  if (isLoading && !featuredReviews?.length) {
    return <Skeleton variant="rectangular" height={40} />;
  }

  if (!featuredReviews?.length && !isEditMode) return null;

  if (isEditMode && !featuredReviews?.length) {
    return (
      <EmptySectionBlock
        sectionProps={{
          title: t(`${TP}.FN_FEATURED_REVIEW`),
          description: t('FEATURED_REVIEW_DESC'),
          emptySections: createHorizontalSections([
            {
              title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, { section: t(`${TP}.FN_FEATURED_REVIEW`).toLowerCase() }),
              linkProps: navigate.getLinkProps({
                entity,
                entityType,
                edit: true,
                queryParams: {
                  tab: artistEditTabValues.reviews,
                  [VALID_QUERY_PARAMS.OPEN_MODAL]: REVIEW_SECTIONS.GENERAL_REVIEWS,
                },
              }),
              trackingData,
            },
          ]),
          ...(!hasPremiumSubscription && {
            premiumSection: {
              type: PREMIUM_NUDGE_VIEW_TYPES.CONCISE,
              enabled: true,
              placement: PREMIUM_NUDGE_POSITION.TOP,
              nudgeSourceType: POPUP_SOURCES.FEATURE_REVIEW,
              trackingData,
            },
          }),
        }}
      />
    );
  }

  return (
    <>
      <SectionBlock
        withTitle={!!isEditMode}
        {...(isEditMode && {
          title: t(`${TP}.FN_FEATURED_REVIEW`),
          editMode: {
            enabled: true,
            hasContent: !hasPremiumSubscription,
            linkProps,
            trackingData,
          },
          ...(!hasPremiumSubscription && {
            className: classes.sectionBlock,
            premiumSection: {
              enabled: true,
              type: isMobile ? PREMIUM_NUDGE_VIEW_TYPES.DEFAULT : PREMIUM_NUDGE_VIEW_TYPES.INLINE,
              isHidden: true,
              nudgeSourceType: POPUP_SOURCES.DEFAULT_TRIAL || '',
              trackingData,
              styles: {
                root: classes.premiumNudge,
              },
            },
          }),
          styles: {
            gradient: classes.sectionBlock__gradient,
            header: classes.sectionBlockHeader,
            leftBoxIcon: classes.leftBoxIcon,
          },
        })}
      >
        <Carousel
          options={{
            maxVisibleItems: 1,
            hideDisabledArrow: isDesktop ? featuredReviews.length === 1 : true,
            autoScroll: true,
            styles: {
              slide: classes.slide,
            },
          }}
        >
          {featuredReviews?.map(review => (
            <div
              key={review?.id}
              className={classnames(classes.reviews, { [classes.reviews__withoutMargin]: !hasPremiumSubscription })}
            >
              <div className={classnames(classes.review, trackingClasses)}>
                {review?.highlight?.length && (
                  <MultilineEllipses
                    {...(!isMobile && { lines: 1 })}
                    showRightChevron={false}
                    styles={{ anchor: classes.readMore }}
                    onClick={e => onOpenReviewModal(e, review)}
                  >
                    <Typography size={isDesktop ? 16 : 12} secondaryFont className={classes.reviewTitle}>
                      {review.highlight}
                    </Typography>
                  </MultilineEllipses>
                )}
              </div>
              <div className={classes.reviewContent}>
                <Typography size={isDesktop ? 12 : 11}>{review?.signature}</Typography>
                {review?.reviewUrl && (
                  <LinkButton
                    rightIcon={<SpriteIcon icon="open_in_new" size="16" className={classes.openInNewIcon} />}
                    variant="text"
                    isLink
                    external
                    href={review.reviewUrl}
                    onClick={e => e.stopPropagation()}
                  />
                )}
              </div>
            </div>
          ))}
        </Carousel>
      </SectionBlock>
      {modalReview && <ReviewModal content={modalReview} onCloseModal={() => setModalReview(false)} />}
    </>
  );
};

export default EntityFeaturedReview;
