import React, { useMemo } from 'react';
import usePageContext from 'utils/hooks/usePageContext';
import dynamic from 'next/dynamic';
import { ENTITY_DETAILS_TAB, ENTITY_MAIN_TABS, ENTITY_TYPE } from 'constants/index';

// TODO: Hack for dynamic import CSS missing issue - with assumption that users will be navigating to home of that entity
// TODO: Remove this once next js solves for this issue https://github.com/vercel/next.js/issues/33286
import ArtistHome from 'components/Artist/Display/Home';
import OrganizationHome from 'components/Organization/Display/Home';
import { useEntitySectionTabs } from 'utils/globals';

const Reviews = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/Reviews'));
const ReviewsPage = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/ReviewsPage'));
const Videos = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/Videos'));
const IntroShowreelGallery = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/IntroShowreel'));
const AuditionShowreelGallery = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/AuditionShowreel'));
const VideoGallery = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/VideoGallery'));
const DigitalVideoPage = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/DigitalVideoPage'));
const Photos = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/Photos'));
const PhotoGallery = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/PhotoGallery'));
const OrganizationContacts = dynamic(() => import('components/Organization/Display/OrganizationContacts'));
const Biography = dynamic(() => import('components/Artist/Display/Biography'));
const Works = dynamic(() => import('components/Globals/Layout/StubPage/Tabs/Works'));
const AboutOrg = dynamic(() => import('components/Organization/Display/AboutOrg'));
const ArtistContacts = dynamic(() => import('components/Artist/Display/ArtistContacts'));
const Venues = dynamic(() => import('components/Organization/Display/Venues'));
const EntityRentalsList = dynamic(() => import('components/Productions/Display/EntityRentalsList'));
const SeasonsWrapper = dynamic(() => import('components/Productions/Display/Seasons'));
const Repertoire = dynamic(() => import('components/Artist/Display/Repertoire'));

const EntityTabComponent = ({ mainPath: mainPathname, isEditMode, isPreviewMode = false }) => {
  const { entityType, subPath, entity, isLoading } = usePageContext();
  const tabs = useEntitySectionTabs({ entityType, entity, isEditMode });
  const mainPath = useMemo(() => {
    if (isPreviewMode) {
      const activeTab = tabs?.find(({ key }) => key === mainPathname);
      return activeTab ? mainPathname : null;
    }
    return mainPathname;
  }, [mainPathname, tabs, isPreviewMode]);

  const TabComponent = useMemo(() => {
    switch (mainPath) {
      case ENTITY_MAIN_TABS.VIDEOS:
        switch (subPath) {
          case ENTITY_DETAILS_TAB.DIGITAL:
            return DigitalVideoPage;
          case ENTITY_DETAILS_TAB.GENERAL:
            return VideoGallery;
          case ENTITY_DETAILS_TAB.INTRO_SHOWREEL:
            return IntroShowreelGallery;
          case ENTITY_DETAILS_TAB.AUDITION_SHOWREEL:
            return AuditionShowreelGallery;
          default: {
            return Videos;
          }
        }
      case ENTITY_MAIN_TABS.IMAGES:
        switch (subPath) {
          case ENTITY_DETAILS_TAB.GENERAL:
            return PhotoGallery;
          default: {
            return Photos;
          }
        }
      case ENTITY_MAIN_TABS.REVIEWS: {
        switch (subPath) {
          case ENTITY_DETAILS_TAB.GENERAL:
            return ReviewsPage;
          default: {
            return Reviews;
          }
        }
      }
      case ENTITY_MAIN_TABS.VENUES: {
        return Venues;
      }
      case ENTITY_MAIN_TABS.PERFORMANCES: {
        return SeasonsWrapper;
      }
      case ENTITY_MAIN_TABS.WORKS: {
        return Works;
      }
      case ENTITY_MAIN_TABS.ABOUT: {
        return AboutOrg;
      }
      case ENTITY_MAIN_TABS.BIO: {
        return Biography;
      }
      case ENTITY_MAIN_TABS.REPERTOIRE: {
        return Repertoire;
      }
      case ENTITY_MAIN_TABS.CONTACT:
        switch (entityType) {
          case ENTITY_TYPE.ARTIST:
            return ArtistContacts;
          default: {
            return OrganizationContacts;
          }
        }
      case ENTITY_MAIN_TABS.RENTALS:
        return EntityRentalsList;
      case ENTITY_MAIN_TABS.HOME:
      default:
        if (!mainPath) {
          switch (entityType) {
            case ENTITY_TYPE.ORGANIZATION:
              return OrganizationHome;
            case ENTITY_TYPE.ARTIST:
              return ArtistHome;
            default:
              return null;
          }
        }

        return null;
    }
  }, [mainPath, subPath, entityType]);

  return (
    <TabComponent
      entityType={entityType}
      entity={entity}
      isLoading={isLoading}
      mainPath={mainPath}
      subPath={subPath}
      isEditMode={isEditMode}
      isPreviewMode={isPreviewMode}
    />
  );
};

export default EntityTabComponent;
