import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';

import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';

import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import { ENTITY_MAIN_TABS } from 'constants/index';

import classes from './Navigation.module.scss';

const Navigation = ({ activeTab, tabs, entityName, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate, showGlobalHeader } = usePageContext();
  const [selectedChildElement, setSelectedChildElement] = useState(null);
  const navRef = useRef(null);

  useEffect(() => {
    if (navRef.current) {
      setSelectedChildElement(navRef.current.querySelector(`.${classes.active}`));
    }
  }, [activeTab?.key]);

  return (
    <nav className={classes.navigation} ref={navRef}>
      <HorizontalScrollIndicators
        triggerSize={0}
        selectedChildElement={selectedChildElement}
        className={classes.navigation__tabs}
        styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
        padding={16}
      >
        <ul className={classes.navigation__list}>
          {tabs?.map(({ key, title, linkProps }) => {
            const isActive =
              (activeTab?.key && activeTab?.key === key) || (!activeTab?.key && key === ENTITY_MAIN_TABS.HOME);
            return (
              <li className={classes.navigation__listItem} key={`link_${key}`}>
                <LinkButton
                  variant="native"
                  shape="rectangle"
                  active={isActive}
                  styles={{
                    root: classnames(classes.navigation__link, classes.navigation__listItem, {
                      [classes.active]: isActive,
                    }),
                  }}
                  {...linkProps}
                  onClick={event => {
                    setSelectedChildElement(event.currentTarget);

                    if (typeof window !== 'undefined') {
                      navigate.scrollTo({ behavior: 'instant' });
                      showGlobalHeader();
                    }
                  }}
                  stopPropagation
                  disableUnderline
                  disableHover
                  isLink
                  trackingData={{
                    ...trackingData,
                    meta: {
                      tab: key,
                    },
                  }}
                  title={`${entityName} ${t(title)}`}
                >
                  <Typography
                    className={classnames(classes.navigation__tab, {
                      [classes.activeTab]: isActive,
                    })}
                    size={14}
                  >
                    {t(title)}
                  </Typography>
                </LinkButton>
              </li>
            );
          })}
        </ul>
      </HorizontalScrollIndicators>
    </nav>
  );
};

export default Navigation;
