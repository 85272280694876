import React, { useCallback } from 'react';
import { useQuery } from 'utils/react-query';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { TertiaryButton } from 'components/uiLibrary/LinkButton';

import queries from 'containers/Artist/queries';

import useEntityEditPermission from 'utils/hooks/useEntityEditPermission';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import { createDate } from 'utils/date';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import { useDialogs } from 'utils/hooks/useDialogs';
import { setCookie } from 'utils/cookie';

import { useTranslation, Trans, i18n } from 'src/i18n';

import { METRIC_TYPES, DATE_FORMATS, TP, ENTITY_TYPE } from 'constants/index';
import { PROFILE_SWITCH } from 'constants/cookieConstants';

import classes from './InsightsCount.module.scss';

const InsightLabel = ({ hasSubscription, impressionData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { isMobile } = useDeviceTypeLayouts();

  if (hasSubscription) {
    return isMobile ? (
      <Typography className={classes.text}>{impressionData?.cumulativeInsightsCount || 0}</Typography>
    ) : (
      <Trans
        i18nKey={t(`${TP}.FN_INSIGHT_COUNT_LAST_YEAR`, { count: impressionData?.cumulativeInsightsCount || 0 })}
        components={{
          span: <Typography weight="bold" className={classes.text} />,
        }}
      />
    );
  }

  return t('VIEW_PROFILE_INSIGHTS');
};

function InsightsCount({ entity: profile, trackingData }) {
  const { navigate, entityType, entity, isArtistInRoster, hasTargetProfileAccess } = usePageContext();
  const { setNoSubscriptionPopup, setPendingAccessPopup } = useNotificationPopup();
  const { canEdit, isVerificationPending, hasSubscription } = useEntityEditPermission({
    entityType,
    entity: profile,
  });
  const { setIsOpenCheckout, setIsRequestAccessModalOpen } = useDialogs();

  const currentDate = createDate().format(DATE_FORMATS.FULL_DATE);
  const oneYearAgo = createDate()
    .subtract(1, 'year')
    .format(DATE_FORMATS.FULL_DATE);

  const query = {
    entityId: profile?.id,
    category: 'insights',
    from: oneYearAgo,
    to: currentDate,
    aggregate: 'month',
  };

  const { data: impressionData } = useQuery(
    queries.getInsights({
      metric: METRIC_TYPES.IMPRESSIONS_TIMESERIES,
      queryConfig: {
        enabled: !!profile?.id && hasSubscription,
        select: response => ({
          cumulativeInsightsCount: response?.data?.data?.[0]?.stats?.timeseries?.reduce(
            (acc, item) => acc + item.value,
            0,
          ),
        }),
      },
      ...query,
    }),
  );

  const handleCheckPermissionPopup = useCallback(
    event => {
      if (canEdit) return;

      if (isArtistInRoster) {
        if (hasTargetProfileAccess && hasSubscription) {
          const linkProps = navigate.getLinkProps({ entityType, entity, edit: true, onlyLinkProps: true });
          setCookie(PROFILE_SWITCH, true);
          window.location.href = `/auth/switchProfile?profileId=${entity?.id}&pageUrl=${linkProps?.as}&lang=${i18n.language}`;
        } else {
          setIsRequestAccessModalOpen(true);
        }
        return;
      }

      if (!hasSubscription) {
        event.preventDefault();

        if (entityType === ENTITY_TYPE.ARTIST) {
          setIsOpenCheckout(true);
          return;
        }

        setNoSubscriptionPopup(true);
        return;
      }

      if (isVerificationPending) {
        event.preventDefault();
        setPendingAccessPopup(true);
      }
    },
    [
      canEdit,
      isArtistInRoster,
      hasSubscription,
      isVerificationPending,
      hasTargetProfileAccess,
      navigate,
      entityType,
      entity,
      setIsRequestAccessModalOpen,
      setNoSubscriptionPopup,
      setIsOpenCheckout,
      setPendingAccessPopup,
    ],
  );

  return (
    <TertiaryButton
      size="medium"
      leftIcon={<SpriteIcon icon="trending_up" />}
      {...navigate.getLinkProps({
        entityType,
        entity: profile,
        edit: true,
      })}
      preventDefault={!canEdit}
      styles={{ root: classes.insightCountBtn }}
      {...(canEdit && { isLink: true })}
      onClick={handleCheckPermissionPopup}
      trackingData={trackingData}
    >
      <InsightLabel hasSubscription={hasSubscription} impressionData={impressionData} />
    </TertiaryButton>
  );
}

export default InsightsCount;
