import React from 'react';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';

import getLinkProps from 'utils/globals/getLinkProps';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';

import classes from './SwitchToEditModeBanner.module.scss';

const SwitchToEditModeBanner = ({ entity, nonStick, isEditMode = false }) => {
  const { t } = useTranslation('NS_BANNERS');

  return (
    <div className={classnames(classes.root, { [classes.nonstickBanner]: !!nonStick })}>
      <Typography weight="bold" size={12}>
        {!isEditMode && t(`${TP}.VIEWING_AS`)} {entity?.name}
      </Typography>

      {isEditMode ? (
        <Typography weight="bold" size={12} color="secondary" className={classes.subtext}>
          {t(`${TP}.FN_EDIT_MODE_BANNER`)}
        </Typography>
      ) : (
        <LinkButton
          variant="text"
          isLink
          styles={{ root: classes.link }}
          scroll
          rightIcon={<SpriteIcon icon="chevron_right" size={14} className={classes.rightIcon} />}
          {...getLinkProps({ entity, entityType: entity?.profileType?.slug })}
        >
          <Typography weight="bold" size={12} color="secondary" className={classes.subtext}>
            {t('UPDATE_YOUR_PROFILE')}
          </Typography>
        </LinkButton>
      )}
    </div>
  );
};

export default SwitchToEditModeBanner;
