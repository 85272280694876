import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import usePageContext from 'utils/hooks/usePageContext';

import classes from './AdvertisementBlock.module.scss';

const VerticalCastingTool = dynamic(() => import('./VerticalAds/CastingTool'));
const HorizontalClaimProfile = dynamic(() => import('./HorizontalAds/ClaimProfile'));

export const AD_LAYOUT = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

const AdvertisementBlock = ({ layout = AD_LAYOUT.VERTICAL }) => {
  const {
    permissions: { isLoggedIn },
  } = usePageContext();

  const AdComponent = useMemo(() => {
    let adsList = [];

    if (layout === AD_LAYOUT.VERTICAL) {
      adsList = [
        {
          component: VerticalCastingTool,
          isValid: true,
        },
      ];
    } else if (layout === AD_LAYOUT.HORIZONTAL) {
      adsList = [
        {
          component: HorizontalClaimProfile,
          isValid: !isLoggedIn,
        },
      ];
    }

    if (adsList?.filter(({ isValid }) => isValid).length === 0) {
      return null;
    }

    const renderIdx = Math.floor(Math.random() * adsList.length);

    return adsList[renderIdx]?.component;
  }, [layout, isLoggedIn]);

  if (!AdComponent) {
    return null;
  }

  return (
    <div
      className={classnames(classes.root, {
        [classes.vertical]: layout === AD_LAYOUT.VERTICAL,
        [classes.horizontal]: layout === AD_LAYOUT.HORIZONTAL,
      })}
    >
      <AdComponent />
    </div>
  );
};

export default AdvertisementBlock;
