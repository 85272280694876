import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';

import queries from 'containers/Artist/queries';

import Typography from 'components/uiLibrary/Typography';
import SparkLineChart from 'components/uiLibrary/Charts/SparkLineChart';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import SectionBlock from 'components/Globals/SectionBlock';
import PremiumPlanNudge from 'components/Globals/Layout/PremiumPlanNudge';
import ProfileDropdown from 'components/Globals/ProfileDropdown';
import Badge from 'components/uiLibrary/Badge';

import { useTranslation } from 'src/i18n';
import { createDate } from 'utils/date';
import { DATE_FORMATS, METRIC_TYPES, TP, PREMIUM_NUDGE_VIEW_TYPES, TABS_TO_SHOW, POPUP_SOURCES } from 'constants/index';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useProfileNotificationCounts } from 'utils/queries/notificationCounts';

import { useAccessPendingProfileIds, useActiveProfileSubscriptions } from 'utils/hooks/useAuthenticatedUser';
import { COMPONENTS, SECTIONS } from 'components/Globals/Analytics/constants';
import useTracking from 'components/Globals/Analytics';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester';
import usePageContext from 'utils/hooks/usePageContext';
import classes from './ProfileInsights.module.scss';

const TRACKING_DATA = {
  section: SECTIONS.PROFILE_INSIGHTS,
};

const PendingAction = () => {
  const { t } = useTranslation('NS_INSIGHTS');
  const track = useTracking();
  const { profileNotificationsCount } = useProfileNotificationCounts();
  const [showProfileDropdown, setShowProfileDropdown] = useState(null);
  const trackingData = {
    ...TRACKING_DATA,
    component: COMPONENTS.PENDING_ACTION,
  };
  const trackingClasses = useTrackingClickStyles(trackingData, false);

  const onPendingActionClickHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    setShowProfileDropdown(event?.target);
    track.click(trackingData);
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className={classnames(classes.pendingActions, trackingClasses)}
        onClick={onPendingActionClickHandler}
        onKeyDown={onPendingActionClickHandler}
      >
        <div className={classes.pendingActions__leftSection}>
          <SpriteIcon icon="notifications_black_24dp" size={24} />
          <Typography weight="medium" size="14" className={classes.pendingActions__leftSection_title}>
            {t(`${TP}.FN_PENDING_ACTION`)}
          </Typography>
          {profileNotificationsCount > 0 && (
            <Badge
              className={classes.pendingActions__leftSection_badge}
              content={profileNotificationsCount}
              variant="secondary"
              size="8"
            />
          )}
        </div>
        <SpriteIcon icon="chevron_down" size={16} />
      </div>
      {showProfileDropdown && (
        <ProfileDropdown
          currentTarget={showProfileDropdown}
          isOpen={!!showProfileDropdown}
          currentActiveTab={TABS_TO_SHOW.NOTIFICATIONS}
          onClose={() => setShowProfileDropdown(null)}
          hideBackButton
          hideActions
        />
      )}
    </>
  );
};

const transformData = response => ({
  insightsData: response?.data?.data?.[0]?.stats?.timeseries || [{ value: 0 }, { value: 0 }], // we need min two dummy values to show proper empty line
  cumulativeInsightsCount: response?.data?.data?.[0]?.stats?.timeseries?.reduce((acc, item) => acc + item.value, 0),
});

const ProfileInsights = ({ entity, styles }) => {
  const { t } = useTranslation(['NS_INSIGHTS', 'NS_ENTITY_STUB_PAGE']);
  const track = useTracking();
  const trackingClasses = useTrackingClickStyles(TRACKING_DATA, false);
  const { navigate, entityType, permissions } = usePageContext();
  const { isAdmin } = permissions;
  const { isMobile } = useDeviceTypeLayouts();
  const activeProfileSubscriptions = useActiveProfileSubscriptions();
  const profileIdsPendingAccess = useAccessPendingProfileIds();
  const [triggerStartFreeTrial, setTriggerStartFreeTrial] = useState(false);
  const hasPremiumSubscription = entity?.stats?.isPro;
  const currentDate = createDate().format(DATE_FORMATS.FULL_DATE);
  const oneYearAgo = createDate()
    .subtract(1, 'year')
    .format(DATE_FORMATS.FULL_DATE);
  const showBlurInsights =
    (!activeProfileSubscriptions?.length || profileIdsPendingAccess?.includes(entity?.id)) && !isAdmin;

  const query = {
    entityId: entity?.id,
    category: 'insights',
    from: oneYearAgo,
    to: currentDate,
    aggregate: 'month',
  };
  const { data: impressionData } = useQuery(
    queries.getInsights({
      metric: METRIC_TYPES.IMPRESSIONS_TIMESERIES,
      ...query,
      queryConfig: {
        enabled: !!entity?.id,
      },
    }),
  );

  const { data: pageViewsData } = useQuery(
    queries.getInsights({
      metric: METRIC_TYPES.PAGEVIEWS_TIMESERIES,
      ...query,
      queryConfig: {
        enabled: !!entity?.id && hasPremiumSubscription,
      },
    }),
  );

  const { data: searchAppearancesData } = useQuery(
    queries.getInsights({
      metric: METRIC_TYPES.SEARCH_APPEARANCES_TIMESERIES,
      ...query,
      queryConfig: {
        enabled: !!entity?.id && hasPremiumSubscription,
      },
    }),
  );

  const insightData = useMemo(
    () => [
      {
        title: t(`${TP}.TAB_INSIGHTS_IMPRESSIONS`),
        ...transformData(impressionData),
        showDataAlways: true,
        blur: showBlurInsights,
        trackingData: {
          component: COMPONENTS.IMPRESSIONS,
        },
      },
      {
        title: t(`${TP}.TAB_INSIGHTS_SEARCH_APPEARANCES`),
        nudgeSourceType: POPUP_SOURCES.SEARCH_APPEARANCES_INSIGHTS,
        ...(searchAppearancesData?.data && transformData(searchAppearancesData)),
        blur: showBlurInsights,
        trackingData: {
          component: COMPONENTS.SEARCH_APPEARANCES,
        },
      },
      {
        title: t(`PAGE_VIEWS`),
        ...(pageViewsData?.data && transformData(pageViewsData)),
        nudgeSourceType: POPUP_SOURCES.PAGE_VIEW_INSIGHTS,
        blur: showBlurInsights,
        trackingData: {
          component: COMPONENTS.PAGE_VIEWS,
        },
      },
    ],
    [t, impressionData, searchAppearancesData, pageViewsData, showBlurInsights],
  );

  const onClickHandler = (event, item) => {
    if (event?.stopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (item?.blur) {
      return null;
    }

    if (track) {
      track.click({
        ...TRACKING_DATA,
        ...item?.trackingData,
      });
    }

    if (hasPremiumSubscription || item?.showDataAlways) {
      return navigate.to(
        navigate.getLinkProps({
          entityType,
          entity,
          edit: true,
          onlyLinkProps: true,
          scrollTo: item?.trackingData?.component,
        }),
      );
    }

    setTriggerStartFreeTrial(true);
    return null;
  };

  return (
    <SectionBlock
      enableSeeAll={false}
      className={classnames(classes.sectionBlock, {
        [styles?.root]: !!styles?.root,
      })}
      withTitle={false}
      editMode={{
        hasContent: true,
      }}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography weight="bold" size="16" className={classes.title}>
            {t(`${TP}.FN_OVERVIEW_TITLE_PROFILE_INSIGHTS`)}
          </Typography>
          <Typography size="14" color="secondary">
            <SpriteIcon icon="info_outline" size={18} className={classes.infoIcon} />
            {t(`NS_ENTITY_STUB_PAGE:NON_PREMIUM_SECTION_MSG`)}
          </Typography>
        </div>
        <HorizontalScrollIndicators triggerSize={0} isArrowsEnabled={false}>
          <div className={classes.body}>
            <ul className={classes.cumulativeInsights}>
              {insightData?.map((item, index) => (
                <li
                  key={`${item?.title}-${index}`}
                  tabIndex={0}
                  className={classnames(classes.insight, trackingClasses, {
                    [classes.insight__pointer]: !item?.blur,
                  })}
                  onClick={e => onClickHandler(e, item)}
                  onKeyDown={e => onClickHandler(e, item)}
                >
                  <Typography weight="bold" size="14">
                    {item?.title}:
                  </Typography>
                  <div
                    className={classnames(classes.content, {
                      [classes.content__blur]: !!item?.blur,
                    })}
                  >
                    {hasPremiumSubscription || item?.showDataAlways || item?.blur ? (
                      <>
                        <Typography weight="bold" size="16">
                          {item?.cumulativeInsightsCount || 0}
                          <Typography color="secondary" size="14">
                            {t(`${TP}.FN_INSIGHTS_PAST_DAYS_COUNT`)}
                          </Typography>
                        </Typography>
                        <SparkLineChart dataKey="value" data={item?.insightsData || []} />
                      </>
                    ) : (
                      <PremiumPlanNudge
                        type={PREMIUM_NUDGE_VIEW_TYPES.CONCISE}
                        styles={{ root: classes.nudge }}
                        nudgeSourceType={item?.nudgeSourceType}
                        trackingData={item?.trackingData}
                        triggerStartFreeTrial={triggerStartFreeTrial}
                        onCallback={() => setTriggerStartFreeTrial(false)}
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
            {!isMobile && <PendingAction />}
          </div>
        </HorizontalScrollIndicators>
        {isMobile && <PendingAction />}
      </div>
    </SectionBlock>
  );
};

export default ProfileInsights;
