import React, { useMemo } from 'react';
import classnames from 'classnames';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import SectionBlock from 'components/Globals/SectionBlock';

import { TP, CONTACT_TYPE, CONTACT_ICON, CONTACT_LABEL, ENTITY_TYPE } from 'constants/index';

import { useTranslation } from 'src/i18n';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester';

import classes from './ContactsList.module.scss';

const getContactTitle = ({ entityType, isBoxOffice }) => {
  if (isBoxOffice) {
    return `${TP}.FN_BOX_OFFICE`;
  }
  return entityType === ENTITY_TYPE.ORGANIZATION
    ? `${TP}.FN_CONTACT_INFO_TITLE`
    : `${TP}.FN_ARTIST_DIRECT_CONTACT_TITLE`;
};

const ContactsList = ({
  entityType,
  isBoxOffice,
  contacts,
  showLoginToView,
  onClickHandler,
  sectionProps,
  isEditMode,
  isOverview,
  trackingData,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const contactTypes = useMemo(
    () =>
      Object.keys(contacts)?.filter(contactType =>
        [CONTACT_TYPE.PHONE, CONTACT_TYPE.EMAIL, CONTACT_TYPE.WEBSITE, CONTACT_TYPE.ADDRESS].includes(contactType),
      ),
    [contacts],
  );
  const title = getContactTitle({ entityType, isBoxOffice });
  const trackingClasses = useTrackingClickStyles(trackingData);

  if (!contactTypes?.length) {
    return null;
  }

  return (
    <div>
      <SectionBlock
        title={t(title)}
        {...(!isOverview && {
          styles: {
            root: classes.contactHeader,
            title: classes.contactHeaderTitle,
            header: classes.contactSectionHeader,
            leftBoxIcon: classes.leftBoxIcon,
          },
        })}
        {...sectionProps}
      />

      <div
        className={classnames(classes.contactSection, {
          [classes.contactSection__isEditMode]: !!isEditMode && isOverview,
        })}
      >
        {contactTypes?.map((contactType, index) => {
          const contact = contacts?.[contactType]?.length === 1 ? contacts[contactType][0] : null;
          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              key={index}
              className={classnames(classes.contactItem, trackingClasses, {
                [classes.contactItem__isEditMode]: !!isEditMode && isOverview,
              })}
              onClick={() => onClickHandler(contact, isBoxOffice)}
            >
              <div className={classes.contactRow}>
                <div className={classes.contactLeftSection}>
                  <SpriteIcon className={classes.contactIcon} icon={CONTACT_ICON[contactType]} size={24} />
                  <SpriteIcon
                    className={classes.contactHoverIcon}
                    icon={`${CONTACT_ICON[contactType]}:hover`}
                    size={24}
                  />
                  <Typography size={14} weight="medium" variant="p" className={classes.contactName}>
                    {t(CONTACT_LABEL[contactType])}
                  </Typography>
                  {showLoginToView && (
                    <Typography color="secondary" size={12} className={classes.loginToView}>
                      ({t(`${TP}.FN_LOGIN_TO_VIEW`)})
                    </Typography>
                  )}
                </div>
                <div className={classes.ctaSection}>
                  {!showLoginToView && (
                    <Typography size={12} color="secondary" className={classes.ctaTitle}>
                      {t('VIEW_DETAIL')}
                    </Typography>
                  )}
                  <SpriteIcon icon="chevron_right" size={16} className={classes.rightIcon} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContactsList;
