import React from 'react';
import PropTypes from 'prop-types';
import { Line, LineChart } from 'recharts';

const SparkLineChart = ({ dataKey, data }) => {
  return (
    <LineChart width={40} height={14} data={data}>
      <Line type="linear" dataKey={dataKey} stroke="#1F52BB" strokeWidth={1} dot={false} />
    </LineChart>
  );
};

SparkLineChart.propTypes = {
  dataKey: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default SparkLineChart;
