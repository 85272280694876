import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import EntityShareAccess from 'components/Globals/EntityShareAccess';
import ShareEntity from 'components/Globals/ShareEntity';
import OrgHeaderActionsList from 'components/Organization/Display/OrgHeaderActionsList';
import KebabMenu from 'components/uiLibrary/KebabMenu';
import LinkButton, { SecondaryButton, PrimaryButton, TertiaryButton } from 'components/uiLibrary/LinkButton';
import Modal from 'components/uiLibrary/Modal';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import CastingToolPromo from 'components/Globals/CastingToolPromo';
import FAQ from 'components/Globals/FAQ';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import useEntityEditPermission from 'utils/hooks/useEntityEditPermission';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import { useTranslation, i18n } from 'src/i18n';
import { useDialogs } from 'utils/hooks/useDialogs';
import { setCookie } from 'utils/cookie';
import { COMPONENTS } from 'components/Globals/Analytics/constants';

import { ENTITY_MAIN_TABS, ENTITY_TYPE, TP, ENTITY_DETAILS_TAB } from 'constants/index';
import { PROFILE_SWITCH } from 'constants/cookieConstants';

import InsightsCount from './InsightsCount';

import classes from './Actions.module.scss';
import { useStickyContext } from '../../StickyContainer';

const Actions = ({ entityType, entity, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const isSticky = useStickyContext();
  const { permissions, navigate, mainPath, setEditModeToggle } = usePageContext();
  const { isTargetMyProfile, isTargetMyOrganization, isAdmin } = permissions;
  const { isMobile, isDesktop } = useDeviceTypeLayouts();
  const {
    showEditBtn,
    canEdit,
    isVerificationPending,
    hasSubscription,
    isArtistInRoster,
    hasTargetProfileAccess,
  } = useEntityEditPermission({
    entityType,
    entity,
  });
  const [isOpen, setIsOpen] = useState(false);
  const { setNoSubscriptionPopup, setPendingAccessPopup } = useNotificationPopup();
  const { setIsOpenCheckout, setIsRequestAccessModalOpen, isPreviewModalOpen } = useDialogs();

  const handleCloseModalDrawer = () => {
    setIsOpen(false);
  };

  const handleCheckPermissionPopup = useCallback(
    event => {
      if (canEdit) return;

      if (isArtistInRoster && !hasSubscription && !hasTargetProfileAccess) {
        setIsRequestAccessModalOpen(true);
        return;
      }

      if (hasTargetProfileAccess) {
        const linkProps = navigate.getLinkProps({ entityType, entity, onlyLinkProps: true });
        setCookie(PROFILE_SWITCH, true);
        window.location.href = `/auth/switchProfile?profileId=${entity?.id}&pageUrl=${linkProps?.as}&lang=${i18n.language}`;
        return;
      }

      if (!hasSubscription) {
        event.preventDefault();

        if (entityType === ENTITY_TYPE.ARTIST) {
          setIsOpenCheckout(true);
          return;
        }

        setNoSubscriptionPopup(true);
        return;
      }

      if (isVerificationPending) {
        event.preventDefault();
        setPendingAccessPopup(true);
      }
    },
    [
      canEdit,
      hasSubscription,
      isVerificationPending,
      isArtistInRoster,
      entityType,
      setNoSubscriptionPopup,
      setIsOpenCheckout,
      setPendingAccessPopup,
      navigate,
      entity,
      hasTargetProfileAccess,
      setIsRequestAccessModalOpen,
    ],
  );

  const { primaryActions, collapsedActions } = useMemo(() => {
    // TODO: Connect to data
    const hasTickets = entity?.stats?.tickets?.exists;
    const hasWatchOptions = entity?.stats?.media?.videos?.digital?.exists;
    const actions = {
      primary: [],
      collapsed: [],
    };

    if (hasTickets && mainPath !== ENTITY_MAIN_TABS.PERFORMANCES) {
      const ticketCTA = {
        label: t(`${TP}.FN_TICKETS`),
        leftIcon: <SpriteIcon icon="tickets" size={16} />,
        isLink: true,
        ...navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.PERFORMANCES, onlyLinkProps: true }),
        size: 'medium',
        trackingData: {
          ...trackingData,
          component: COMPONENTS.TICKET_CTA,
        },
      };
      actions.primary.push(ticketCTA);
      actions.collapsed.push(ticketCTA);
    }

    if (hasWatchOptions) {
      const watchOptionsCTA = {
        label: t(`${TP}.m_WATCH`),
        leftIcon: <SpriteIcon icon="play_circle_outline" size={16} />,
        size: 'medium',
        isLink: true,
        ...navigate.getLinkProps({
          entityType,
          entity,
          path: `${ENTITY_MAIN_TABS.VIDEOS}/${ENTITY_DETAILS_TAB.DIGITAL}`,
          ...(entityType === ENTITY_TYPE.ARTIST && { pro: false }),
        }),
        trackingData: {
          ...trackingData,
          component: COMPONENTS.WATCH_OPTIONS_CTA,
        },
      };
      actions.primary.push(watchOptionsCTA);
      actions.collapsed.push(watchOptionsCTA);
    }

    if (isTargetMyOrganization || isTargetMyProfile) {
      actions.collapsed.push(
        <ShareEntity
          entityType={entityType}
          entity={entity}
          className={classes.shareEntityListItem}
          trackingData={{
            ...trackingData,
            component: COMPONENTS.SHARE_CTA,
          }}
        />,
        <EntityShareAccess
          entityType={entityType}
          entity={entity}
          isMoreAction
          trackingData={{
            ...trackingData,
            component: COMPONENTS.SHARE_ACCESS_CTA,
          }}
        />,
        {
          label: 'FAQs',
          leftIcon: <SpriteIcon icon="question_mark" size={14} />,
          size: 'medium',
          isLink: true,
          href: '/pro/all',
        },
        <LinkButton
          leftIcon={<SpriteIcon icon="edit" />}
          {...navigate.getLinkProps({ entityType, entity, edit: true, onlyLinkProps: true })}
          isLink
          size="medium"
          variant="quaternary"
          styles={{
            root: classes.editListItem,
          }}
          trackingData={{
            ...trackingData,
            component: COMPONENTS.EDIT_CTA,
          }}
        >
          {t(`${TP}.m_EDIT`)}
        </LinkButton>,
      );
    }

    return {
      primaryActions: !isTargetMyOrganization && !isTargetMyProfile ? actions.primary : [],
      collapsedActions: actions.collapsed,
    };
  }, [entity, entityType, isTargetMyOrganization, isTargetMyProfile, t, navigate, mainPath]);
  const showShareEntity = !showEditBtn || isDesktop;
  const hideLeftActions = (isSticky && showEditBtn) || (!showShareEntity && !primaryActions?.length);

  if (isMobile && isSticky) {
    return null;
  }

  return (
    <div
      className={classnames(classes.actions, {
        [classes.actions__emptyLeftActions]: hideLeftActions,
      })}
    >
      {(!showEditBtn || showShareEntity) && (
        <div
          className={classnames(classes.leftSide, {
            [classes.leftSide__hidden]: hideLeftActions,
          })}
        >
          {!showEditBtn &&
            primaryActions?.map((action, index) => (
              <div key={index}>
                <SecondaryButton shape="rounded" {...action}>
                  {action?.label}
                </SecondaryButton>
              </div>
            ))}
          {showShareEntity && (
            <ShareEntity
              entityType={entityType}
              entity={entity}
              showLabelText={!isMobile || !primaryActions?.length}
              className={classnames({
                [classes.leftSide__shareEntity]: !primaryActions?.length,
              })}
              trackingData={{
                ...trackingData,
                component: COMPONENTS.SHARE_CTA,
              }}
            />
          )}
        </div>
      )}

      <div className={classes.rightSide}>
        {(!isSticky || (isSticky && !showEditBtn)) && (
          <>
            {entityType === ENTITY_TYPE.ARTIST && isAdmin && !isSticky && (
              <TertiaryButton
                leftIcon={<SpriteIcon icon="switch_profile" />}
                styles={{ root: classes.editModeBtn }}
                onClick={() => setEditModeToggle(true)}
                size="medium"
              >
                Edit mode
              </TertiaryButton>
            )}
            <FAQ
              trackingData={{
                ...trackingData,
                component: COMPONENTS.FAQ_CTA,
              }}
            />
            {entityType === ENTITY_TYPE.ARTIST && (
              <div className={classes.rightSide__castingToolPromo}>
                <CastingToolPromo
                  entityType={entityType}
                  entity={entity}
                  asCTA
                  trackingData={{
                    ...trackingData,
                    component: COMPONENTS.VIEW_IN_CASTING_TOOL_CTA,
                  }}
                />
              </div>
            )}
            {(isTargetMyOrganization || isTargetMyProfile) && !isMobile && (
              <div className={classes.rightSide__shareAccess}>
                <EntityShareAccess
                  entityType={entityType}
                  entity={entity}
                  trackingData={{
                    ...trackingData,
                    component: COMPONENTS.SHARE_ACCESS_CTA,
                  }}
                />
              </div>
            )}
            {isTargetMyProfile && !isPreviewModalOpen && (
              <div className={classes.rightSide__insights}>
                <InsightsCount
                  entity={entity}
                  trackingData={{
                    ...trackingData,
                    component: COMPONENTS.VIEW_PROFILE_INSIGHTS_CTA,
                  }}
                />
              </div>
            )}
          </>
        )}
        {isTargetMyProfile && isSticky && !isPreviewModalOpen && (
          <div className={classes.rightSide__insights}>
            <InsightsCount
              entity={entity}
              trackingData={{
                ...trackingData,
                component: COMPONENTS.VIEW_PROFILE_INSIGHTS_CTA,
              }}
            />
          </div>
        )}
        {showEditBtn && !isPreviewModalOpen && (
          <div className={classes.rightSide__editBtn}>
            <PrimaryButton
              leftIcon={<SpriteIcon icon="edit" />}
              {...navigate.getLinkProps({ entityType, entity, edit: true, onlyLinkProps: true })}
              {...(canEdit && { isLink: true })}
              size="medium"
              styles={{
                root: classes.editAction,
              }}
              preventDefault={!canEdit}
              onClick={handleCheckPermissionPopup}
              trackingData={{
                ...trackingData,
                component: COMPONENTS.EDIT_CTA,
              }}
            >
              {t(`${TP}.m_EDIT`)}
            </PrimaryButton>
          </div>
        )}
        {showEditBtn && isMobile && <KebabMenu options={collapsedActions} />}
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModalDrawer} allowMobileDrawer title={t(`${TP}.FN_MORE_ACTION`)}>
        <OrgHeaderActionsList
          entityType={entityType}
          entity={entity}
          onClose={handleCloseModalDrawer}
          showEditBtn={showEditBtn}
          handleCheckPermissionPopup={handleCheckPermissionPopup}
        />
      </Modal>
    </div>
  );
};

export default Actions;
