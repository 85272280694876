import React, { useMemo, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useQuery } from 'utils/react-query';
import useTracking from 'components/Globals/Analytics';
import classnames from 'classnames';
import mediaQueries from 'containers/Media/queries';
import globalQueries from 'containers/Globals/queries';
import reviewsQueries from 'containers/Reviews/queries';
import { artistEditTabValues } from 'containers/Artist/constants';

import Navigation from 'components/Globals/Layout/StubPage/Navigation';
import ContentWithFilters from 'components/Globals/Layout/ContentWithFilters';
import CastingToolPromo from 'components/Globals/CastingToolPromo';
import Header from 'components/Globals/Layout/StubPage/Header';
import Actions from 'components/Globals/Layout/StubPage/Actions';
import MediaWidget from 'components/Media/Display/MediaWidget';
import Breadcrumbs from 'components/uiLibrary/Breadcrumbs';
import EntitySocialLinks from 'components/Globals/EntitySocialLinks';
import JsonLD, { JSON_LD_TYPES } from 'components/Globals/JsonLD';
import EntityBioSummary from 'components/Globals/EntityBioSummary';
import EntitySuggestions from 'components/Globals/EntitySuggestions';
import CustomerLogos from 'components/Globals/CustomerLogos';
import Drawer from 'components/uiLibrary/Drawer';
import SwitchToEditModeBanner from 'components/NotificationBanner/banners/SwitchToEditModeBanner';
import EntityTabComponent from 'components/Globals/Layout/StubPage/EntityTabComponent';

import ProfileInsights from 'components/Artist/Display/ProfileInsights';
import EntityFeaturedReview from 'components/Globals/EntityFeaturedReview';
import { SECTIONS, COMPONENTS, CATEGORIES, GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import useOnScrollBeyondElement from 'utils/hooks/useOnScrollBeyondElement';
import useScrollDirection from 'utils/hooks/useScrollDirection';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useEntitySectionTabs } from 'utils/globals';
import { useBreadcrumbs } from 'utils/seo/index';
import { useDialogs } from 'utils/hooks/useDialogs';
import { getCookie } from 'utils/cookie';
import useEntityEditPermission from 'utils/hooks/useEntityEditPermission';
import { TRACK_EVENTS } from 'utils/tracking';
import useScrollBlocker from 'utils/hooks/useScrollBlocker';
import { useProfileNotificationCounts } from 'utils/queries/notificationCounts';
import { useAccessPendingProfileIds, useUserData } from 'utils/hooks/useAuthenticatedUser';

import {
  ENTITY_TYPE,
  ENTITY_MAIN_TABS,
  PRODUCTION_VALIDITY_IDENTIFIERS,
  SCROLL_DIRECTION,
  TP,
  PROFILE_TYPE_IDS,
} from 'constants/index';
import { IS_CHECKOUT_ACTIVE, IS_ACTION_CENTER } from 'constants/cookieConstants';
import { useTranslation } from 'src/i18n';

import useBioDescriptions from 'utils/hooks/useBioDescriptions';
import Explore from '../Explore';
import EditModeActions from '../EditModeActions';

import classes from './BaseWrapper.module.scss';

const AOSOnboarding = dynamic(() => import('components/Globals/Accounts/Onboarding/AOSOnboarding'));
const EditDisclaimerModal = dynamic(() => import('components/Globals/Accounts/Common/EditDisclaimerModal'));

const entityHeaderTrackingData = {
  section: SECTIONS.ENTITY_HEADER,
};

const artistInlineSuggestionsTrackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  component: COMPONENTS.ARTIST_CARD,
  category: CATEGORIES.RECOMMENDED,
};

const claimProfileTrackingData = {
  section: SECTIONS.RECOMMENDED_ARTISTS,
  component: COMPONENTS.CLAIM_OR_CREATE_PROFILE,
};

const artistSuggestionsTrackingData = {
  section: SECTIONS.PEOPLE_ALSO_VIEWED,
  component: COMPONENTS.ARTIST_CARD,
  category: CATEGORIES.RECOMMENDED,
};

const organizationSuggestionsTrackingData = {
  section: SECTIONS.PEOPLE_ALSO_VIEWED,
  component: COMPONENTS.ORGANIZATION_CARD,
  category: CATEGORIES.RECOMMENDED,
};

const entityNavigationTrackingData = {
  ...entityHeaderTrackingData,
  component: COMPONENTS.NAVIGATE_CTA,
};

const exploreMoreTrackingData = {
  section: SECTIONS.EXPLORE_MORE,
  component: COMPONENTS.NAVIGATE_CTA,
};

const EntitySuggestionWrapper = ({ entity, entityType, isEditMode, mainPath, hasSuggestions }) => (
  <div
    className={classnames(classes.entitySuggestionsInline, {
      [classes.entitySuggestionsInline__isOverview]: !mainPath,
      [classes.entitySuggestionsInline__isEditMode]: isEditMode,
    })}
  >
    <EntitySuggestions
      entityType={entityType}
      entity={entity}
      hasInlineSuggestions={hasSuggestions}
      inline
      isEditMode={isEditMode}
      trackingData={artistInlineSuggestionsTrackingData}
      claimProfileTrackingData={claimProfileTrackingData}
    />
  </div>
);

export const BaseWrapperChildren = ({ children, isEditMode, isQuickViewMode = false }) => {
  const {
    entity,
    entityType,
    mainPath,
    subPath,
    validFilters,
    appliedFilterSlugs,
    hideGlobalHeader,
    showGlobalHeader,
    permissions,
    isHeaderVisible,
    isGlobalNavigationVisible,
    showGlobalNavigation,
    hideGlobalNavigation,
    navigate,
  } = usePageContext();
  const { isPreviewModalOpen, setIsOpenCheckout, isEditDisclaimerModal, isAlreadyClaimedModal } = useDialogs();
  const isOpenPreviewModal = isQuickViewMode || isPreviewModalOpen;
  useScrollBlocker(isOpenPreviewModal);
  const { isTargetMyProfile } = permissions;
  const profileIdsWithAccess = useAccessPendingProfileIds();
  const userData = useUserData();
  const hasProfileAccessPending = profileIdsWithAccess?.includes(entity?.id);
  const { isArtistInRoster, hasTargetProfileAccess, hasSubscription } = useEntityEditPermission({
    entityType,
    entity,
  });
  const track = useTracking();
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { isMobile, isDesktop } = useDeviceTypeLayouts();
  const {
    setIsSwitchProfileModalOpen,
    setIsRequestAccessModalOpen,
    setIsProfileAccessRequestsModalOpen,
    setIsOpenIdentityVerification,
    isArtistNoAccessModalOpen,
  } = useDialogs();
  const { profileNotifications } = useProfileNotificationCounts();
  const tabs = useEntitySectionTabs({ entityType, entity, isEditMode });
  const { getSubPath } = navigate.getLinkProps({ entity, entityType, edit: true });

  const activeTab = tabs?.find(({ key }) => key === mainPath);
  const showPaidSection = entity?.stats?.isPro;

  const {
    stickyNavTopPosition,
    stickyHeaderTopPosition,
    stickyGlobalHeaderNavigation,
    editModeHeaderActionsTopPosition,
  } = useMemo(() => {
    // IMP: in change of any values please do test the sticky header and nav on page scroll in all devices
    if (isDesktop) {
      return {
        stickyNavTopPosition: 120,
        stickyHeaderTopPosition: 55,
        stickyGlobalHeaderNavigation: 92,
        editModeHeaderActionsTopPosition: 0,
      };
    }

    if (isMobile) {
      return {
        stickyNavTopPosition: isOpenPreviewModal ? 210 : isEditMode ? 140 : 90,
        stickyHeaderTopPosition: 50,
        stickyGlobalHeaderNavigation: 92,
        editModeHeaderActionsTopPosition: !mainPath ? 102 : 105,
      };
    }

    return {
      stickyNavTopPosition: isOpenPreviewModal ? 210 : isEditMode ? 140 : 90,
      stickyHeaderTopPosition: 50,
      stickyGlobalHeaderNavigation: 92,
      editModeHeaderActionsTopPosition: !mainPath ? 102 : 130,
    };
  }, [isDesktop, isMobile, isOpenPreviewModal, isEditMode, mainPath]);

  const { ref: headerRef, isScrolledBeyond: isHeaderScrolledBeyond } = useOnScrollBeyondElement({
    topPosition: stickyHeaderTopPosition,
    enabled: !isOpenPreviewModal || !isDesktop,
  });
  const {
    ref: globalNavigationRef,
    isScrolledBeyond: isHeaderScrolledBeyondGlobalNavigation,
  } = useOnScrollBeyondElement({
    topPosition: stickyGlobalHeaderNavigation,
    enabled: !isOpenPreviewModal,
  });
  const { ref: breadcrumbsRef, isScrolledBeyond: isBreadcrumbScrolledBeyond } = useOnScrollBeyondElement();
  const {
    ref: editModeHeaderActionsRef,
    isScrolledBeyond: isEditModeHeaderActionsScrolledBeyond,
  } = useOnScrollBeyondElement({ topPosition: editModeHeaderActionsTopPosition, enabled: isEditMode && !isDesktop });
  const { ref: navRef, isScrolledBeyond: isNavScrolledBeyond } = useOnScrollBeyondElement({
    topPosition: stickyNavTopPosition,
    enabled: !isOpenPreviewModal || !isDesktop,
  });

  const showStickyHeader = isOpenPreviewModal || (!isHeaderVisible && !isGlobalNavigationVisible);
  const scrollDirection = useScrollDirection({
    onScrollDown: () => {
      if (isHeaderScrolledBeyondGlobalNavigation && isGlobalNavigationVisible) {
        hideGlobalNavigation();
      } else if (isHeaderScrolledBeyond && isHeaderVisible) {
        hideGlobalHeader();
      }
    },
    onScrollUp: () => {
      if (!isHeaderScrolledBeyond && !isHeaderVisible) {
        showGlobalHeader();
      } else if (!isHeaderScrolledBeyondGlobalNavigation && !isGlobalNavigationVisible && isHeaderVisible) {
        showGlobalNavigation();
      } else if (
        isHeaderScrolledBeyond &&
        !isHeaderVisible &&
        !isGlobalNavigationVisible &&
        isHeaderScrolledBeyondGlobalNavigation
      ) {
        showGlobalHeader();
        showGlobalNavigation();
      }
    },
    enabled: !isOpenPreviewModal,
  });
  const hideStickyHeader =
    (isDesktop ? isNavScrolledBeyond : isHeaderVisible && isBreadcrumbScrolledBeyond) &&
    scrollDirection === SCROLL_DIRECTION.UP &&
    !showStickyHeader;

  const breadcrumbs = useBreadcrumbs({
    entityType,
    entity,
    appliedFilterSlugs,
    ...(activeTab?.title && { activeTab: { ...activeTab, translatedTitle: t(activeTab?.title) } }),
  });

  const { hasBio: orgBioExist = false } = useBioDescriptions({
    entity,
    entityType,
    withLanguage: false,
  });

  const { data: indBioExist = false } = useQuery(
    globalQueries.getEntityBioDetails({
      entityType,
      entityId: entity?.id,
      queryConfig: {
        enabled: !!entity?.stats?.about?.exists && entityType === ENTITY_TYPE.ARTIST,
        select: response => response?.data?.length > 0,
      },
    }),
  );

  const { data: hasMediaWidget } = useQuery(
    mediaQueries.getMediaSuggestions({
      entityType,
      entityId: entity?.id,
      queryConfig: {
        enabled: !mainPath && entity?.stats?.media?.widget?.exists,
        select: response => response?.data?.length > 0,
      },
    }),
  );

  const { data: hasFeaturedReviews } = useQuery(
    reviewsQueries.getFeaturedReviews({
      entityType,
      entityId: entity?.id,
      limit: 10,
      ...(isEditMode && {
        asEdit: true,
      }),
      queryConfig: {
        enabled:
          !!entity?.id &&
          entityType === ENTITY_TYPE.ARTIST &&
          ((entity?.stats?.reviews?.featured?.exists && showPaidSection) || isEditMode),
        select: response => isEditMode || !!response?.data?.some(review => review?.highlight?.id),
      },
    }),
  );

  const isMediaWidgetExists = hasMediaWidget && !mainPath;
  const showBiography = orgBioExist || indBioExist;
  const showPreviewModeHeader = !!isPreviewModalOpen && !isDesktop && !mainPath && isMediaWidgetExists;
  const showHeaderAlways = showStickyHeader || isDesktop || mainPath || !isMediaWidgetExists || isEditMode;

  const jsonLDType = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPE.ARTIST:
        return JSON_LD_TYPES.SOCIAL_PROFILE;
      case ENTITY_TYPE.ORGANIZATION:
        return JSON_LD_TYPES.LOCAL_BUSINESS;
      case ENTITY_TYPE.PRODUCTION:
        return JSON_LD_TYPES.WEBPAGE;
      default: {
        return null;
      }
    }
  }, [entityType]);

  const commonFilterParams = useMemo(() => {
    let baseFilters = {};

    if (entityType === ENTITY_TYPE.ARTIST) {
      baseFilters = {
        ...baseFilters,
        include_virtual_contributors: true,
      };
    }

    if (mainPath === ENTITY_MAIN_TABS.RENTALS) {
      return {
        ...baseFilters,
        has_active_rental: true,
        approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
      };
    }

    return baseFilters;
  }, [mainPath, entityType]);

  const showEntitySuggestions =
    entityType === ENTITY_TYPE.ARTIST &&
    (entity?.stats?.customizations?.suggestedEntities?.visibility || !showPaidSection);

  useEffect(() => {
    const isCheckoutActive = getCookie(IS_CHECKOUT_ACTIVE);
    if (isCheckoutActive === 'true') {
      setIsOpenCheckout(true);
    }
  }, [setIsOpenCheckout]);

  useEffect(() => {
    const isCheckoutActive = getCookie(IS_CHECKOUT_ACTIVE);
    const isActionCenterCookie = getCookie(IS_ACTION_CENTER);
    const noActiveModals = !isCheckoutActive && !isActionCenterCookie;
    const shouldShowSwitchProfileModal =
      entityType === ENTITY_TYPE.ARTIST &&
      hasTargetProfileAccess &&
      hasSubscription &&
      !isTargetMyProfile &&
      !isEditMode &&
      !isOpenPreviewModal;
    const shouldShowRequestAccessModal =
      isArtistInRoster && !hasProfileAccessPending && !hasSubscription && !hasTargetProfileAccess;

    if (noActiveModals) {
      if (shouldShowSwitchProfileModal) {
        setIsSwitchProfileModalOpen(true);
        track.click(
          {
            name: TRACK_EVENTS.VIEW_ARTIST_SWITCH_PROFILE_MODAL,
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      }
      if (shouldShowRequestAccessModal) {
        setIsRequestAccessModalOpen(true);
      }
    }
  }, [
    entity?.id,
    setIsRequestAccessModalOpen,
    isArtistInRoster,
    setIsSwitchProfileModalOpen,
    hasTargetProfileAccess,
    hasSubscription,
    isEditMode,
    isOpenPreviewModal,
    hasProfileAccessPending,
    entityType,
    profileIdsWithAccess,
    track,
    isTargetMyProfile,
  ]);

  useEffect(() => {
    if (
      profileNotifications?.profileAccessRequests &&
      isTargetMyProfile &&
      !hasProfileAccessPending &&
      !isEditDisclaimerModal &&
      !isArtistNoAccessModalOpen &&
      !isAlreadyClaimedModal
    ) {
      setIsProfileAccessRequestsModalOpen(true);
      track.click(
        {
          name: TRACK_EVENTS.VIEW_PROFILE_ACCESS_REQUEST_MODAL,
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );
    }
  }, [
    hasProfileAccessPending,
    isArtistNoAccessModalOpen,
    isEditDisclaimerModal,
    isTargetMyProfile,
    profileNotifications?.profileAccessRequests,
    setIsProfileAccessRequestsModalOpen,
    track,
  ]);

  useEffect(() => {
    if (userData?.isSuspicious && entityType === ENTITY_TYPE.ARTIST) {
      setIsOpenIdentityVerification(true);
    }
  }, [userData?.isSuspicious, setIsOpenIdentityVerification, entityType]);

  return (
    <div className={classes.root}>
      {entity && <JsonLD type={jsonLDType} entityType={entityType} entity={entity} activeTab={activeTab} />}
      <div
        className={classnames(classes.floatingHeader, {
          [classes.floatingHeader__isSticky]: showHeaderAlways,
          [classes.floatingHeader__isEditMode]: isEditMode,
          [classes.floatingHeader__enableShadow]: showStickyHeader && isBreadcrumbScrolledBeyond,
          [classes.floatingHeader__isNonDesktopOverviewPage]:
            !isDesktop && !mainPath && isMediaWidgetExists && !isEditMode,
          [classes.floatingHeader__isNonDesktopOverviewPage_addPadding]:
            !!isOpenPreviewModal ||
            (!isNavScrolledBeyond && isHeaderScrolledBeyond && showStickyHeader && isBreadcrumbScrolledBeyond),
          [classes.previewModeFloatingHeader]: !!showPreviewModeHeader,
          [classes.previewModeFloatingHeader__visible]: !!showPreviewModeHeader && !!isHeaderScrolledBeyond,
          [classes.floatingHeader__separator]: !isBreadcrumbScrolledBeyond && isEditMode,
          [classes.hideHeader]: !!hideStickyHeader,
        })}
      >
        <div
          className={classnames(classes.entityHeader, {
            [classes.entityHeader__marginBottom]: isNavScrolledBeyond,
          })}
        >
          <Header
            entityType={entityType}
            entity={entity}
            hideActions={!isDesktop}
            isEditMode={isEditMode}
            isSticky={showStickyHeader && isBreadcrumbScrolledBeyond}
            {...((isDesktop || mainPath || !isMediaWidgetExists) && {
              isHeaderVisible: !isBreadcrumbScrolledBeyond,
            })}
          />

          {isEditMode && !isDesktop && isEditModeHeaderActionsScrolledBeyond && showStickyHeader && (
            <EditModeActions
              entityType={entityType}
              entity={entity}
              styles={{
                root: classnames(classes.editModeHeaderActions__isSticky, {
                  [classes.editModeHeaderActions__isSticky_visible]:
                    isEditModeHeaderActionsScrolledBeyond && showStickyHeader,
                }),
              }}
              trackingData={entityHeaderTrackingData}
              showOnlyActions
            />
          )}

          <div
            {...(isDesktop && { ref: navRef })}
            className={classnames(classes.navigation, classes.stickyNavigation, {
              [classes.stickyNavigation__sticky]: isDesktop ? isBreadcrumbScrolledBeyond : isHeaderScrolledBeyond,
              [classes.stickyNavigation__hide]: !isNavScrolledBeyond,
              [classes.navigation__isEditMode]: !!isEditMode,
              [classes.stickyNavigation__sticky_isEditMode]: !!isEditMode,
              [classes.navigation__isPreviewMode]: !!isOpenPreviewModal,
            })}
          >
            <Navigation
              activeTab={activeTab}
              tabs={tabs}
              entityName={entity?.name}
              trackingData={entityNavigationTrackingData}
            />
          </div>
        </div>
      </div>
      {isEditMode && <SwitchToEditModeBanner entity={entity} nonStick isEditMode />}
      {showEntitySuggestions && !isEditMode && (
        <EntitySuggestionWrapper
          entity={entity}
          entityType={entityType}
          mainPath={mainPath}
          hasSuggestions={showEntitySuggestions}
        />
      )}
      <BreadcrumbComponent
        {...{
          isMobile,
          breadcrumbs,
          entityType,
          entity,
          isMediaWidgetExists,
          breadcrumbsRef,
          isEditMode,
          trackingData: entityHeaderTrackingData,
        }}
      />
      {/* NOTE: In case we are doing change in any of the below two div, please make sure to test sticky header scroll functionality */}
      <div
        ref={headerRef}
        className={classnames(classes.header, {
          [classes.header__isOverview]: !mainPath || isEditMode,
        })}
      />
      <div
        ref={globalNavigationRef}
        className={classnames(classes.header, {
          [classes.header__isOverview]: !mainPath || isEditMode,
        })}
      />
      <div
        className={classnames(classes.navigation, {
          [classes.navigation__sticky]: isEditMode ? isBreadcrumbScrolledBeyond : isHeaderScrolledBeyond,
          [classes.navigation__isEditMode]: !!isEditMode,
        })}
        {...(!isDesktop && { ref: navRef })}
      >
        <Navigation
          activeTab={activeTab}
          tabs={tabs}
          entityName={entity?.name}
          trackingData={entityNavigationTrackingData}
        />
      </div>
      {!mainPath && (isMediaWidgetExists || isEditMode) && (
        <div
          className={classnames(classes.mediaWidget, {
            [classes.mediaWidget__isEditMode]: !!isEditMode,
          })}
        >
          <MediaWidget entityType={entityType} entity={entity} breadcrumbs={breadcrumbs} isEditMode={isEditMode} />
        </div>
      )}
      {isEditMode && (
        <>
          {!isDesktop && (
            <EditModeActions
              entityType={entityType}
              entity={entity}
              styles={{
                root: classes.editModeHeaderActions,
              }}
              trackingData={entityHeaderTrackingData}
              rootRef={editModeHeaderActionsRef}
            />
          )}
          {!mainPath && <ProfileInsights entity={entity} styles={{ root: classes.profileInsights }} />}
        </>
      )}

      {hasFeaturedReviews && !mainPath && (
        <div
          className={classnames(classes.featuredReviews, {
            [classes.featuredReviews__isEditMode]: !!isEditMode,
          })}
        >
          <EntityFeaturedReview entity={entity} entityType={entityType} isEditMode={isEditMode} />
        </div>
      )}

      {!mainPath && showBiography && !isDesktop && !isEditMode && (
        <div
          className={classnames(classes.biography, {
            [classes.biography__withoutMediaWidget]: !isMediaWidgetExists,
            [classes.biography__isEditMode]: !!isEditMode,
          })}
        >
          <EntityBioSummary entityType={entityType} entity={entity} isEditMode={isEditMode} isOverview />
        </div>
      )}
      <div
        className={classnames(classes.contactActions, {
          [classes.contactActions__withMediaWidget]: isMediaWidgetExists && !showBiography,
          [classes.contactActions__isEditMode]: !!isEditMode,
        })}
      >
        {!mainPath && entity?.stats?.contacts?.exists && (
          <div className={isEditMode ? classes.socialLinks : classes.socialLinks__previewModal}>
            <EntitySocialLinks
              entity={entity}
              entityType={entityType}
              inline
              showGeneralContact={entityType === ENTITY_TYPE.ORGANIZATION}
              {...(isEditMode
                ? {
                    isEditMode,
                    sectionProps: {
                      editMode: {
                        enabled: true,
                        hasContent: true,
                        linkProps: getSubPath({
                          queryParams: { tab: artistEditTabValues.digitalPresence },
                        }),
                      },
                    },
                  }
                : { marginTopNone: true })}
              trackingData={entityHeaderTrackingData}
            />
          </div>
        )}
      </div>
      {!isEditMode && (
        <div className={classes.actions}>
          <Actions entityType={entityType} entity={entity} trackingData={entityHeaderTrackingData} />
        </div>
      )}
      {entityType === ENTITY_TYPE.ARTIST && !isEditMode && (
        <div
          className={classnames(classes.castingToolPromo, {
            [classes.castingToolPromo__withoutMediaWidget]: !isMediaWidgetExists,
          })}
        >
          <CastingToolPromo
            entityType={entityType}
            entity={entity}
            trackingData={{ ...entityHeaderTrackingData, component: COMPONENTS.VIEW_IN_CASTING_TOOL_CTA }}
          />
        </div>
      )}
      <div
        className={classnames(classes.tabContent, {
          [classes.tabContent__isEditMode]: !!isEditMode,
        })}
      >
        <ContentWithFilters
          ns="NS_ENTITY_STUB_PAGE"
          entityType={entityType}
          entity={entity}
          filterTypes={
            mainPath &&
            mainPath !== ENTITY_MAIN_TABS.PERFORMANCES &&
            mainPath !== ENTITY_MAIN_TABS.REPERTOIRE &&
            !subPath &&
            validFilters
          }
          commonFilterParams={commonFilterParams}
          filterStickyTop={105}
        >
          {children}
          {entityType === ENTITY_TYPE.ORGANIZATION && permissions?.isTargetMyOrganization && (
            <AOSOnboarding orgId={entity?.id} />
          )}
        </ContentWithFilters>
        {showEntitySuggestions && isEditMode && (
          <EntitySuggestionWrapper
            entity={entity}
            entityType={entityType}
            mainPath={mainPath}
            hasSuggestions={showEntitySuggestions}
            isEditMode={isEditMode}
          />
        )}
        <div className={classes.exploreContainer}>
          <Explore inline trackingData={exploreMoreTrackingData} />
        </div>
        {[ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType) && (
          <div className={classes.entitySuggestionsFooter}>
            <EntitySuggestions
              entityType={entityType}
              entity={entity}
              isArrowsEnabled={isMobile}
              hasInlineSuggestions={showEntitySuggestions}
              styles={{
                sectionBlock: classes.footerSectionBlock,
                horizontalScroll: classes.entitySuggestionsFooter__horizontalScroll,
              }}
              trackingData={
                entityType === ENTITY_TYPE.ARTIST ? artistSuggestionsTrackingData : organizationSuggestionsTrackingData
              }
            />
          </div>
        )}
        <CustomerLogos className={classes.trustedByBlock} showTitle />
        {permissions?.isTargetMyProfile && (
          <EditDisclaimerModal profileId={entity?.id} profileTypeId={PROFILE_TYPE_IDS.ARTIST} />
        )}
      </div>
    </div>
  );
};

const BreadcrumbComponent = ({
  isMobile,
  breadcrumbs,
  entityType,
  entity,
  isMediaWidgetExists,
  breadcrumbsRef,
  isEditMode,
  trackingData,
}) => (
  <div
    ref={breadcrumbsRef}
    className={classnames(classes.breadcrumbs, {
      [classes.breadcrumbs__isEditMode]: !!isEditMode,
      [classes.breadcrumbs__withoutMediaWidget]: !isMediaWidgetExists && !isEditMode,
    })}
  >
    <Breadcrumbs data={breadcrumbs} {...(!!isMobile && { maxItems: 2 })} />
    {!isEditMode && (
      <div className={classes.breadcrumbs__castingToolPromo}>
        <CastingToolPromo
          entityType={entityType}
          entity={entity}
          asCTA
          trackingData={{ ...trackingData, component: COMPONENTS.VIEW_IN_CASTING_TOOL_CTA }}
        />
      </div>
    )}
  </div>
);

const BaseWrapper = ({ isEditMode, mainPath }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { isPreviewModalOpen, setIsPreviewModalOpen } = useDialogs();

  return (
    <>
      <BaseWrapperChildren isEditMode={isEditMode}>
        <EntityTabComponent mainPath={mainPath} isEditMode={isEditMode} />
      </BaseWrapperChildren>
      {isPreviewModalOpen && (
        <Drawer
          title={t(`${TP}.PROFILE_MANAGE_PREVIEW`)}
          className={classes.drawer}
          isOpen={isPreviewModalOpen}
          onClose={() => setIsPreviewModalOpen(false)}
          styles={{
            headerText: classes.headerText,
            headerCloseIcon: classes.headerCloseIcon,
            content: classes.drawerContent,
            drawerRoot: classes.previewDrawerRoot,
          }}
        >
          <BaseWrapperChildren>
            <EntityTabComponent mainPath={mainPath} isPreviewMode={isPreviewModalOpen} />
          </BaseWrapperChildren>
        </Drawer>
      )}
    </>
  );
};

export default BaseWrapper;
