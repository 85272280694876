import React, { useMemo } from 'react';
import classnames from 'classnames';
import { PrimaryButton, TertiaryButton } from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import EntityShareAccess from 'components/Globals/EntityShareAccess';

import { useActiveProfileData, useActiveProfileSubscriptions } from 'utils/hooks/useAuthenticatedUser';
import usePageContext from 'utils/hooks/usePageContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import { PREMIUM_NUDGE_VIEW_TYPES, TP, POPUP_SOURCES, PROFILE_TYPES, ENTITY_TYPE } from 'constants/index';
import { useTranslation } from 'src/i18n';

import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import PremiumPlanNudge from '../../PremiumPlanNudge';

import classes from './EditModeActions.module.scss';

const EditModeActions = ({ rootRef, entityType, entity, styles, trackingData = {}, showOnlyActions = false }) => {
  const { t } = useTranslation(['NS_APP_GLOBALS', 'NS_ENTITY_STUB_PAGE']);
  const { setIsPreviewModalOpen, setIsOpenCheckout } = useDialogs();
  const activeProfile = useActiveProfileData();
  const activeProfileSubscriptions = useActiveProfileSubscriptions();
  const { isMobile } = useDeviceTypeLayouts();
  const { navigate, permissions, setEditModeToggle } = usePageContext();
  const isArtistHasProSubscription = entity?.stats?.isPro;
  const isCheckout = useMemo(
    () => activeProfileSubscriptions?.[0]?.paymentType !== 'free' && activeProfile.subscriptionStatus === 'basic',
    [activeProfileSubscriptions, activeProfile.subscriptionStatus],
  );

  const { currentPlanTitle, currentPlanSubTitle, hasPremiumPlan } = useMemo(() => {
    let planTitle = '';
    let planSubTitle = '';
    let isPremium = false;
    switch (entity?.subscriptionStatus) {
      case PROFILE_TYPES.CHURNED:
        planTitle = t(`${TP}.FN_FREE`);
        planSubTitle = t('PREMIUM_PLAN_ENDED');
        break;
      case PROFILE_TYPES.PRO:
        planTitle = t(`${TP}.FN_PROFILE_SUBSCRIPTION_TYPE_PREMIUM`);
        isPremium = true;
        break;
      default:
        if (permissions?.isAdmin) {
          planTitle = t(`NS_ENTITY_STUB_PAGE:${TP}.FN_UNCLAIMED`);
          break;
        }
        planTitle = t(`${TP}.FN_FREE`);
        break;
    }
    return {
      currentPlanTitle: planTitle,
      currentPlanSubTitle: planSubTitle,
      hasPremiumPlan: isPremium,
    };
  }, [entity?.subscriptionStatus, t, permissions?.isAdmin]);

  return (
    <div
      ref={rootRef}
      className={classnames(classes.root, {
        [styles?.root]: !!styles?.root,
      })}
    >
      {!showOnlyActions && (
        <div className={classes.currentPlanNudge}>
          {currentPlanTitle && (
            <Typography
              size={12}
              color="secondary"
              className={classnames(classes.activePlanLabel, {
                [classes.activePlanLabel__separator]: !isArtistHasProSubscription,
              })}
            >
              {t(`NS_ENTITY_STUB_PAGE:CURRENT_PLAN`)}:
              <Typography
                weight="medium"
                size={10}
                className={classnames(classes.activePlan, {
                  [classes.activePlan__premium]: hasPremiumPlan,
                })}
              >
                {currentPlanTitle}
              </Typography>
              <Typography weight="medium" className={classes.activePlanSubText}>
                {currentPlanSubTitle}
              </Typography>
            </Typography>
          )}
          {!isArtistHasProSubscription && (
            <PremiumPlanNudge
              type={PREMIUM_NUDGE_VIEW_TYPES.CONCISE}
              styles={{ root: classes.premiumPlanNudge }}
              allowComparePlans
              nudgeSourceType={POPUP_SOURCES.DEFAULT_TRIAL}
              trackingData={trackingData}
            />
          )}
        </div>
      )}
      <div className={classes.root__actions}>
        {entityType === ENTITY_TYPE.ARTIST && permissions?.isAdmin && (
          <TertiaryButton
            leftIcon={<SpriteIcon icon="switch_profile" />}
            styles={{ root: classes.editModeBtn }}
            onClick={() => setEditModeToggle(false)}
            size="medium"
          >
            Display mode
          </TertiaryButton>
        )}
        <EntityShareAccess entityType={entityType} entity={entity} trackingData={trackingData} withoutText={isMobile} />
        <TertiaryButton
          leftIcon={<SpriteIcon icon="visibility_24dp" size={16} className={classes.preview__icon} />}
          onClick={() => setIsPreviewModalOpen(true)}
          styles={{ root: classes.preview__button }}
          size="medium"
          preventDefault
          stopPropagation
          trackingData={{
            ...trackingData,
            subComponent: SUB_COMPONENTS.PROFILE_PREVIEW_CTA,
          }}
        >
          {t(`${TP}.PROFILE_MANAGE_PREVIEW`)}
        </TertiaryButton>
        <PrimaryButton
          leftIcon={<SpriteIcon icon="edit" />}
          {...(isCheckout
            ? { onClick: () => setIsOpenCheckout(true) }
            : navigate.getLinkProps({ entityType, entity, edit: true, onlyLinkProps: true }))}
          isLink={!isCheckout}
          size="medium"
          styles={{
            root: classes.editBtn,
          }}
          trackingData={{
            ...trackingData,
            subComponent: SUB_COMPONENTS.EDIT_CTA,
          }}
        >
          {t(`${TP}.m_EDIT`)}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default EditModeActions;
