import { SNACKBAR_VARIANTS, enqueueSnackbar } from 'components/uiLibrary/Snackbar';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'utils/react-query';
import queries from 'containers/Artist/queries';

import { errorMessageConverter } from 'utils/common';
import { useTranslation } from 'src/i18n';
import { TP } from 'constants/index';

import usePageContext from './usePageContext';

const useProfileCustomizationToggle = ({ entity, key, enabled = true }) => {
  const [profileCustList, setProfileCustList] = useState({});
  const { refetchEntityDetails } = usePageContext();
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  const {
    data: profileCustomization,
    isLoading,
    refetch: refetchProfileCustomization,
    isRefetching: isRefetchProfileCustomization,
  } = useQuery(
    queries.getProfileCustomizations({
      id: entity?.id,
      queryConfig: {
        enabled,
      },
    }),
  );

  const { mutate: saveCheckListPreferencesMutation } = useMutation(
    queries.saveCheckListPreferences({
      onError: err => handleRefreshEntity(errorMessageConverter(err), SNACKBAR_VARIANTS.ERROR),
      onSuccess: () => handleRefreshEntity(t(`${TP}.FN_SUCCESS_UPDATING_DATA`), SNACKBAR_VARIANTS.SUCCESS),
    }),
  );

  useEffect(() => {
    if (profileCustomization?.length > 0) {
      setProfileCustList(
        profileCustomization?.reduce((res, checkItem) => {
          res[checkItem?.key] = { ...checkItem };
          return res;
        }, {}),
      );
    }
  }, [profileCustomization, isRefetchProfileCustomization]);

  const handleRefreshEntity = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
    if (variant === SNACKBAR_VARIANTS.ERROR) {
      setProfileCustList(null);
    } else {
      refetchEntityDetails();
    }
    refetchProfileCustomization();
  };

  const onToggle = useCallback(
    value => {
      if (enabled) {
        if (typeof value === 'boolean') {
          saveCheckListPreferencesMutation({
            data: {
              value,
            },
            endpointParams: { id: entity?.id, prefId: profileCustList?.[key]?.id },
          });
        }
      }
    },
    [entity?.id, saveCheckListPreferencesMutation, profileCustList, key, enabled],
  );
  return { data: profileCustList, isLoading, onToggle };
};

export default useProfileCustomizationToggle;
